import { mainnet, bsc, bscTestnet, sepolia, polygon } from 'wagmi/chains'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { http, fallback } from 'wagmi'

const projectId = process.env.NEXT_PUBLIC_PROJECT_ID

export const config = getDefaultConfig({
  appName: 'RNSID',
  projectId: projectId,
  chains: [mainnet, bsc, bscTestnet, sepolia, polygon],
  transports: {
    [mainnet.id]: fallback([
      http(),
      http('https://mainnet.infura.io/v3/2154cb9483024ecea38c9dcee79f3603'),
      http('https://eth-mainnet.g.alchemy.com/v2/A8_DFKZzEjpNp4olgy271VdhjL9n0xGe'),
    ]),
    [polygon.id]: fallback([http(), http('https://polygon-mainnet.infura.io/v3/2154cb9483024ecea38c9dcee79f3603')]),
    [bsc.id]: fallback([http(), http('https://bsc-dataseed1.ninicoin.io	'), http('https://bsc-dataseed1.defibit.io')]),
    [bscTestnet.id]: fallback([http()]),
    [sepolia.id]: fallback([http()]),
  },
})
