import {isServer} from '@/utils/isServer';

const ReactNativeWebViewEvent = {
  willLoad: "ReactNativeWebViewEvent_Will_Load",
  windowReady: "ReactNativeWebViewEvent_Window_Ready",
  documentReady: "ReactNativeWebViewEvent_Document_Ready",
  methodCallBack: "ReactNativeWebViewEvent_Method_Call_Back",
}

interface ReactNativeWebMethodCallBackProps {
  method: string
  params?: any
  error?: string
}

interface ReactNativeWebProps  {
    event: string
    data?: ReactNativeWebMethodCallBackProps
}

export const needNativeHandle = () => {
  // @ts-ignore
  return (!isServer && window && window.ReactNativeWebView && /rns_mobile_app/i.test(window.navigator.userAgent)) ? true : false
}

export const sendMessage = (method: string, params: any[] = []) => {
  if (needNativeHandle()) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      encodeURIComponent(
        JSON.stringify({
          method: method,
          params: encodeURIComponent(JSON.stringify([...params])),
        }),
      ),
    )
    return true
  }
  return false
}

const sendlEventMsg = (event: string) => {
  const params = [event]
  return sendMessage('reactNativeWebViewEvent', params)
}

const __eventMap: {[key: string]:(res?: any, error?: string) => void } = {}

const addCallBack = (method: string, callback :(res?: any, error?: string) => void) => {
  console.log('addCallBack ',method);
  __eventMap[method] = callback
}

const removeCallBack = (method: string) => {
  try {
    console.log('removeCallBack ',method);
    __eventMap[method] = undefined
  } catch (error) {
    console.log('removeCallBack error :',error);
  }
  
}

const getCallBack = (method: string) => {
  return __eventMap[method]
}

const handleMessage = (event: {
  origin: string, data: {rnsdata: string} 
}) => {
  if (event.origin.includes('livechatinc.com')) {
    return
  }
  if (event.data && event.data.rnsdata) {
    console.log('has receive post data: ',JSON.stringify(event.data));
    const res: ReactNativeWebProps = JSON.parse(decodeURIComponent(event.data.rnsdata))
    console.log('receive post data: ',event.data, res);
    if (res.event === ReactNativeWebViewEvent.methodCallBack) {
      console.log('receive methodCallBack: ',res.data);
      if (res.data) {
        const callback = getCallBack(res.data.method)
        callback && callback(res.data.params ?? null, res.data.error)
      }
    } 
  }
}

export const addNativeReceiver = (callback: (event: { data: {rnsdata: string} })=> void = handleMessage) => {
  if (needNativeHandle()) {
    sendlEventMsg(ReactNativeWebViewEvent.willLoad)
    if (window) {
      // @ts-ignore
      window.addEventListener("message", callback);
      sendlEventMsg(ReactNativeWebViewEvent.windowReady);
    }    
  }
}

export const sendlanguageChangeMsg = (lang: string) => {
    const params = [lang]
    return sendMessage('setlanguage', params)
}

export const sendPopNaviMsg = () => {
  const params = []
  return sendMessage('navipop', params)
}

export const sendPaySuccessMsg = () => {
  const params = []
  return sendMessage('paysuccess', params)
}

export const sendLoginMsg = (token: string) => {
  const params = [token]
  return sendMessage('login', params)
}

export const socailAccountAction = (type: 'google-oauth2' | 'apple-id', action: 'login'| 'binding') => {
  return asyncSendMessage<{token: string, email: string}>('socialBinding', [type, action])
}

export const signature = (msg: string) => {
  return asyncSendMessage<string>('signature', [msg])
}

export const getAccount = () => {  
  return asyncSendMessage<string>('getAccount')
}

export const watchAccount = (callback: (account: string) => void) => {  
  return watchMessage<string>('watchAccount',callback)
}

export const getNetwork = (chainId: number) => {
  return asyncSendMessage<void>('getNetwork', [chainId])
}

export const watchNetwork = (callback: (chainId: number) => void) => {  
  return watchMessage<number>('watchNetwork',callback)
}

export const connect = () => {
  return asyncSendMessage<string>('connect')
}

export const disconnect = () => {
  return asyncSendMessage<void>('disconnect')
}

export const switchNetwork = (chainId: number) => {
  return asyncSendMessage<void>('switchNetwork', [chainId])
}

export const requestPermissions = () => {
  return asyncSendMessage<void>('requestPermissions', [])
}

export const readContract = <T>(config: { address: string, chainId?: number, abi: any, method: string, args: any[], overrides: {} }) => {
  return asyncSendMessage<T>('readContract', [config])
}

export const writeContract = (config: { address: string, chainId?: number, method: string, args: any[], overrides: {} }) => {
  return asyncSendMessage<{hash: string}>('writeContract', [config])
}

const asyncSendMessage = <T>(method: string, params?: any) => {
  console.log('will asyncSendMessage ',method, params);

  return new Promise<T>((resolve, reject) => {
    try {
      addCallBack(method, (value, error) => {
        removeCallBack(method)
        if (value || value === null) {
          resolve(value ?? undefined)
        } else {
          reject(error ?? 'there are some error occur on bridge callback')
        }
      })
      sendMessage(method, params)
    } catch (error) {
        reject(error)
    }
  })
}

const watchMessage = <T>(method: string, callback: (res: T) => void) => {
  console.log('addWatchMessage ',method)
  addCallBack(method, (value) => {
    console.log(method,' watch call back ',value)
    if (value) {
      callback(value)
    }
  })
  sendMessage(method)
  return () => removeCallBack(method)
}
