import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export async function activate(locale: string) {
  const { messages } = await import(`@/locales/${locale}/messages.po`)
  console.log('activate locale:', locale)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export async function loadCatalog(locale: string) {
  const catalog = await import(`@lingui/loader!@/locales/${locale}/messages.po`)
  return catalog.messages
}

export async function useLinguiInit(messages) {
  const router = useRouter()
  const isClient = typeof window !== 'undefined'
  if (!isClient && router.pathname === '/') {
    i18n.loadAndActivate({ locale: 'en', messages })
  }
  if (isClient && !i18n.locale && router.pathname === '/') {
    const locale = window.localStorage.getItem('rns-lng') || 'en'
    // first client render
    i18n.loadAndActivate({ locale: 'en', messages })
  }
  useEffect(() => {
    const locale = window.localStorage.getItem('rns-lng') || 'en'
    const localeDidChange = locale !== i18n.locale
    if (localeDidChange) {
      activate(locale)
    }
  }, [])
  return i18n

  // const router = useRouter()
  // // const locale = router.locale || router.defaultLocale!
  // const isClient = typeof window !== 'undefined'

  // console.log('i18n.locale:', i18n.locale)
  // console.log(router.pathname)
  // if (!isClient && router.pathname === '/') {
  //   // there is single instance of i18n on the server
  //   // note: on the server, we could have an instance of i18n per supported locale
  //   // to avoid calling loadAndActivate for (worst case) each request, but right now that's what we do
  //   i18n.loadAndActivate({ locale: 'en', messages })
  // }
  // if (isClient && !i18n.locale) {
  //   // first client render
  //   i18n.loadAndActivate({ locale: 'en', messages })
  // }

  // useEffect(() => {
  //   i18n.loadAndActivate({ locale: 'en', messages })
  // }, [])

  // useEffect(() => {
  //   const localeDidChange = locale !== i18n.locale
  //   if (localeDidChange) {
  //     i18n.loadAndActivate({ locale, messages })
  //   }
  // }, [locale])

  // return i18n
}
